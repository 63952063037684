import React from 'react'

import EventCard from '../../components/EventCard'

// import styles from './RelatedEvents.module.css'

import propTypes from 'prop-types'
import { useGetRelatedEventsQuery } from './relatedEventsSlice'

function RelatedEvents ({ eventId }) {
  let content = (<div />)
  const {
    data: relatedEvents = {},
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error
  } = useGetRelatedEventsQuery(eventId)

  if (isLoading || isFetching) {
    console.log('.')
  } else if (isSuccess) {
    console.log('relatedEvents', relatedEvents)
    const cards = []
    relatedEvents.map((event, index) => {
      cards.push(
        <EventCard
            key={`re-${index}`}
          event={event}
        />
      )
      return null
    })

    if (relatedEvents.length) {
      content = (
          <>
            <h4>Related Events</h4>
            <div className="d-flex justify-content-around">
              {cards}

            </div>
          </>
      )
    } else {
      content = (
          <div>

          </div>
      )
    }

    console.log('isSuccess')
  } else if (isError) {
    console.log('isError')
    console.error(error.toString())
  }
  return content
}
RelatedEvents.propTypes = {
  eventId: propTypes.any
}
export default RelatedEvents
