import { api } from '../../slices/apiSlice'

const relatedEventsApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getRelatedEvents: builder.query({
      query: (id) => `published_events/${id}/related`,
      providesTags: ['RelatedEvents']
    })
  }),
  overrideExisting: false
})

export const {
  useGetRelatedEventsQuery
} = relatedEventsApiSlice

export default relatedEventsApiSlice
