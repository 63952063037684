import React from 'react'

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import Form from 'react-bootstrap/Form'

import {
  useGetEventsQuery,
  useGetOrganizersQuery
} from './calendarSlice'
import EventTooltip from '../../components/EventTooltip'

const Calendar = ({ setEventId, organizerId }) => {
  // const [searchQuery, setSearchQuery] = React.useState('')
  const [organizer, setOrganizer] = React.useState(organizerId)
  // const [viewType, setViewType] = React.useState('thread')
  const renderEventContent = (eventInfo) => {
    console.log('eventInfo.event._def', eventInfo.event._def)
    return (<>
          <EventTooltip event={eventInfo.event._def.extendedProps}/>
      </>)
  }
  const viewEvent = (eventInfo) => {
    setEventId(eventInfo.event.id)
  }

  const formatEvents = (data) => data.map((item) => ({
    ...item,
    start: item.startDate,
    end: item.endDate,
    title: item.name,
    backgroundColor: item.organizer.color

  }))

  const {
    data: events = [],
    // isLoading,
    // isFetching,
    isSuccess
    // isError,
    // error
  } = useGetEventsQuery(organizer)

  const {
    data: organizers = [],
    // isLoading: organizersLoading,
    isSuccess: organizersSuccess

  } = useGetOrganizersQuery()

  let content

  let orgMenu = null
  if (organizersSuccess) {
    orgMenu = <Form.Select
        style={{
          width: 'auto'
        }}
        aria-label="Select an Event Organizer"
        onChange={(el) => { setOrganizer(el.target.value) }}
        value={organizer}
    >
      <option value="all">All</option>
      {
        organizers.map((org) => <option key={org.id} value={org.id}>{org.name}</option>)
      }
    </Form.Select>
  }

  if (isSuccess) {
    console.log('isSuccess')

    // const test = formatEvents(events)
    console.log('events', events)

    console.log('organizers', organizers)
    content = (
      <>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {orgMenu}
        </div>
        <div><FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
          initialView="dayGridMonth"
          events={formatEvents(events)}
          eventContent={renderEventContent}
          eventClick={viewEvent}
          eventDisplay="block"
          height={'85vh'}
          themeSystem={'bootstrap5'}
        // eventBackgroundColor="#FF0000"
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,listWeek'
          }}

        />
            <div>
                {organizers.map((org) => <small key={org.id}><span className={'legend-color'} style={{ backgroundColor: org.color }}></span>{org.name}</small>)}
            </div>
        </div>
      </>
    )
  }
  return content
}

Calendar.displayName = 'Calendar'
export default Calendar
