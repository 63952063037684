import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import moment from 'moment'
import propTypes from 'prop-types'
import { FaCalendarPlus, FaEnvelope, FaVideo } from 'react-icons/fa'
import {
  rrulestr
} from 'rrule'
import RelatedEvents from '../related_events/RelatedEvents'

import TagChip from '../../components/TagChip'

import styles from './Event.module.css'

import {
  useGetEventQuery
} from './eventSlice'

import DateRange, { DateRangeString } from '../../components/DateRange'

function Event ({ eventId, setEventId }) {
  let content = (<div/>)
  const {
    data: event = {},
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error
  } = useGetEventQuery(eventId)

  if (isLoading || isFetching) {
    console.log('isLoading')
  } else if (isSuccess) {
    let recurStr
    if (event.recurringEvent && event.recurringEvent.rrule) {
      const rruleSet = rrulestr(event.recurringEvent.rrule)
      recurStr = rruleSet.toText()
    }

    const mailLines = []

    mailLines.push(encodeURIComponent(`Name: ${event.name}`))
    mailLines.push(encodeURIComponent(`Date: ${DateRangeString(event.startDate, event.endDate)}`))

    if (event.location) {
      mailLines.push(encodeURIComponent(`Location: ${event.location.name}`))
      if (event.location.address) {
        mailLines.push(encodeURIComponent(`Address: ${event.location.address}`))
      }
    }

    if ((event.recurringEvent && event.recurringEvent.description) || (event.recurringEvent && event.recurringEvent.description)) {
      mailLines.push('%0D%0A Description:')
    }

    if ((event.recurringEvent && event.recurringEvent.description)) {
      mailLines.push(encodeURIComponent(event.recurringEvent.description))
    }

    if ((event.sessionDescription)) {
      mailLines.push(event.sessionDescription)
    }

    if ((event.video_conference_url)) {
      mailLines.push(encodeURIComponent(`Video Link: ${event.video_conference_url}`))
    }
    if ((event.infoUrl)) {
      mailLines.push('%0D%0A' + encodeURIComponent(`More Information: ${event.infoUrl}`))
    }

    const mailToLink = 'mailto:?subject=' + encodeURIComponent(event.name) + '&body=' + mailLines.join('%0D%0A')

    content = (
            <Modal show={eventId} size="lg" onHide={() => setEventId(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>{event.name}</Modal.Title>
                </Modal.Header>
                <Container>
                    <Row>
                        <Col sm={8}>

                            {event.sessionDescription && (
                                <>
                                    <h5>Session Description</h5>
                                    <div dangerouslySetInnerHTML={{ __html: event.sessionDescription }} />
                                    <hr />
                                </>
                            )}

                            {((event.recurringEvent && event.recurringEvent.description)) && (
                                <>
                                    <h5 className={styles.textPad}>About this event</h5>
                                    {event.imageFile && (<img src={event.imageFile} className={styles.headerImage} alt="" />)}
                                    { event.recurringEvent && event.recurringEvent.description && (<div dangerouslySetInnerHTML={{ __html: event.recurringEvent.description }} />)}

                                    <hr />

                                </>
                            )}

                            {event.infoUrl && (<div><b>More information: </b><a href={event.infoUrl} target={'_blank'} rel="noreferrer">{event.infoUrl}</a></div>)}

                            {/* {!event.imageFile && (<img src="https://placekitten.com/1280/375" className={styles.headerImage} alt="" />)} */}

                            {(event.contact_name || event.contact_name || event.contact_email) && (
                                <div className={styles.textPad}>
                                    <h5>Contact</h5>
                                    <div>
                                        {event.contact_name && (<><b>Name: </b>{event.contact_name}</>)}
                                        {event.contact_phone && (<><br/><b>Phone: </b>{event.contact_phone}</>)}
                                        {event.contact_email && (<><br/><b>Email: </b>{event.contact_email}</>)}
                                    </div>
                                </div>
                            )}
                            {event.isPublic && (<div><i>This event is open to the public</i></div>)}
                            {event.isCME && (<div><i>CME credit is available for this session</i></div>)}
                        </Col>

                        <Col sm={4} className={styles.uibg}>
                            <div className={styles.textPad}>
                                <h5>Date and time</h5>
                                <DateRange startDate={event.startDate} endDate={event.endDate}/>
                                {recurStr && (<div className={styles.textPad}><b>Recurs:</b> {recurStr}</div>)}
                            </div>
                            <hr/>
                            {((event.location) || event.video_conference_url) && (
                                <>
                                    <div className={styles.textPad}>

                                        {(event.location || event.video_conference_url) && (<h5>Location</h5>)}

                                        {event.location && event.video_conference_url && (<div><b>In-person and Virtual</b></div>)}
                                        {event.location && !event.video_conference_url && (<div><b>In-person Only</b></div>)}
                                        {!event.location && event.video_conference_url && (<div><b>Virtual Only</b></div>)}

                                        {((event.location)) && (

                                            <div>
                                                {event.location?.name && (event.location.name)}
                                                {event.location?.address && (<div>{event.location.address}</div>)}
                                            </div>

                                        )}

                                        {event.video_conference_url && (
                                            <div>
                                                <FaVideo/> <a href={event.video_conference_url} target={'_blank'}
                                                              rel="noreferrer">Link</a>
                                            </div>

                                        )}
                                    </div>
                                    <hr/>
                                </>
                            )}

                            <div className={styles.textPad}>
                                <h5>Share</h5>

                                <div>
                                    <a href={`/published/event/${event.id}/ical`}
                                       className={styles.shareLink}><FaCalendarPlus/></a>
                                    <a href={mailToLink}>

                                        <FaEnvelope/></a>

                                </div>
                            </div>

                        </Col>
                    </Row>
                    {event.tags && (
                        <Row>
                            <div className={styles.textPad}><TagChip tags={event.tags}/></div>
                        </Row>
                    )}
                    <Row>
                        <Col sm={12}>

                            <RelatedEvents
                                eventId={eventId}
                            />
                        </Col>
                    </Row>

                </Container>
            </Modal>

    )
    console.log('isSuccess')
  } else {
    if (isError) {
      console.log('isError')
      console.error(error.toString())
    }
  }

  return content
}

Event.propTypes = {
  eventId: propTypes.string,
  setEventId: propTypes.func
}

export default Event
