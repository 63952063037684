import React from 'react'
import moment from 'moment'
import propTypes from 'prop-types'
function DateRange ({ startDate, endDate }) {
  return (<>{DateRangeString(startDate, endDate)}</>)
}

export const DateRangeString = (startDate, endDate) => {
  const fmt1 = new Intl.DateTimeFormat('en', {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
    // timeZone: 'GMT'
  })

  return fmt1.formatRange(moment.utc(startDate), moment.utc(endDate))
}
DateRangeString.propTypes = {
  startDate: propTypes.string,
  endDate: propTypes.string
}
DateRange.propTypes = {
  startDate: propTypes.string,
  endDate: propTypes.string
}
export default DateRange
