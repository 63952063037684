import { api } from '../../slices/apiSlice'

const eventApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getEvent: builder.query({
      query: (id) => `published_events/${id}`,
      providesTags: ['Event']
    })
  }),
  overrideExisting: false
})

export const {
  useGetEventQuery
} = eventApiSlice

export default eventApiSlice
