import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import propTypes from 'prop-types'
import DateRange from '../DateRange'
import styles from '../../features/event/Event.module.css'
import { FaVideo } from 'react-icons/fa'

function EventTooltip ({ event }) {
  console.log('event', event)
  const renderTooltip = (props) => (

        <Popover id="popover-basic" {...props}>
            <Popover.Header as="h3">{event.name} <br /> <DateRange startDate={event.startDate} endDate={event.endDate}/></Popover.Header>
            <Popover.Body>

                {event.imageFile && (<img src={event.imageFile} className={styles.headerImage} alt=""/>)}

                {event.sessionDescription && (
                    <>
                    <h5>Session Description</h5>
                    <div dangerouslySetInnerHTML={{ __html: event.sessionDescription }} />
                    </>
                )}

                {((event.recurringEvent && event.recurringEvent.description)) && (
                    <>
                        <h5 className={styles.textPad}>About this event</h5>
                        {event.imageFile && (<img src={event.imageFile} className={styles.headerImage} alt="" />)}
                        { event.recurringEvent && event.recurringEvent.description && (<div dangerouslySetInnerHTML={{ __html: event.recurringEvent.description }} />)}
                        <hr />
                    </>
                )}

                {(event.contact_name || event.contact_email) && (
                    <div className={styles.textPad}>
                        <h5>Contact</h5>
                        <div>
                            {event.contact_name && (<><b>Name: </b>{event.contact_name}</>)}
                            {event.contact_phone && (<><br/><b>Phone: </b>{event.contact_phone}</>)}
                            {event.contact_email && (<><br/><b>Email: </b>{event.contact_email}</>)}
                        </div>
                    </div>
                )}

                {(event.location || event.video_conference_url) && (<h5>Location</h5>)}

                {event.location && event.video_conference_url && (<div><b>In-person and Virtual</b></div>)}
                {event.location && !event.video_conference_url && (<div><b>In-person Only</b></div>)}
                {!event.location && event.video_conference_url && (<div><b>Virtual Only</b></div>)}

                {((event.location)) && (

                        <div>
                            {event.location?.name && (event.location.name)}
                            {event.location?.address && (<div>{event.location.address}</div>)}
                        </div>

                )}

                {event.video_conference_url && (
                    <div>
                        <FaVideo/> <a href={event.video_conference_url} target={'_blank'}
                                           rel="noreferrer">Link</a>
                    </div>

                )}

            </Popover.Body>

        </Popover>
  )

  return (
        <OverlayTrigger
            placement="auto"
            // delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
        >
            <div>
                <b>{event.title}</b>
                <i>{event.name}</i>
            </div>

        </OverlayTrigger>
  )
}

EventTooltip.propTypes = {
  event: propTypes.object
}
export default EventTooltip
