import React from 'react'
import Card from 'react-bootstrap/Card'
import DateRange from '../DateRange'
import propTypes from 'prop-types'

function CardExample ({ event }) {
  console.log('CardExample', event)
  return (

    <Card style={{ width: '18rem' }}>
      <Card.Header style={{ color: '#FFF', backgroundColor: event.organizer.color }}><Card.Title>{event.name}</Card.Title></Card.Header>
      <Card.Body>
        {event.description}
        <DateRange startDate={event.startDate} endDate={event.endDate} />

      </Card.Body>
    </Card>

  )
}

CardExample.propTypes = {
  event: propTypes.object
}

export default CardExample
