import { api } from '../../slices/apiSlice'

const calendarApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query({
      query: (organizerId) => (organizerId ? `/published_events?organizer.id=${organizerId}` : '/published_events'),
      providesTags: ['Event']
    }),
    getOrganizers: builder.query({
      query: () => '/organizers',
      providesTags: ['Organizer']
    })
  })
})

export const {
  useGetEventsQuery,
  useGetOrganizersQuery
} = calendarApiSlice

export default calendarApiSlice
