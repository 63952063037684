import React from 'react'
import Badge from 'react-bootstrap/Badge'

function TagChip (props) {
  const tags = props.tags.split(',')

  console.log(tags)

  const content = []
  tags.map((tag, index) => content.push(<Badge key={`tag-${index}`} pill>{tag}</Badge>))

  return content
}
TagChip.displayName = 'TagChip'
export default TagChip
