import React from 'react'
import ReactDom from 'react-dom/client'
import { Provider } from 'react-redux'
import store from './app/store'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'

const myTag = document.currentScript
const organizerId = myTag.getAttribute('data-organizer')
const embedTarget = myTag.getAttribute('data-embed-target')
//
let container

const rootElement = document.getElementById('root')
if (rootElement) {
  container = rootElement
} else {
  let containerParent = document.getElementById(embedTarget)
  if (!containerParent) {
    containerParent = document.body
  }
  container = document.createElement('div')
  container.setAttribute('id', 'root')
  containerParent.appendChild(container)
}

const root = ReactDom.createRoot(container)
console.log(root)
//
// const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App
        organizerId={organizerId}
      />
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
