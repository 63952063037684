import React from 'react'
import Calendar from './features/calendar/Calendar'
import Event from './features/event/Event'
import './App.css'
import propTypes from 'prop-types'

// import 'bootstrap/dist/css/bootstrap.min.css'
function App ({ organizerId }) {
  const [eventId, setEventId] = React.useState(null)
  return (
      <>
        {eventId && <Event eventId={eventId} setEventId={setEventId}/>}
        <Calendar
          setEventId={setEventId}
          organizerId={organizerId}
        />
      </>
  )
}

App.propTypes = {
  organizerId: propTypes.number
}
export default App
