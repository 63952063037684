import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const apiBaseURL = process.env.REACT_APP_CALENDAR_API_BASE_URL

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseURL,
    prepareHeaders: (headers) => {
      headers.set('accept', 'application/json')
      return headers
    }
  }),
  tagTypes: ['Event', 'Host', 'Tag'],
  endpoints: () => ({})

})

export default api
